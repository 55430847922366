<template>
  <b-overlay :variant="skin" :show="loading" spinner-variant="warning">
    <template v-slot:overlay>
      <div class="text-center p-4 bg-primary text-light rounded">
        <div class="mb-3">Sincronizando Professor ...</div>
      </div>
    </template>

    <div class="auth-wrapper auth-v1" style="flex-direction: column">
      <img :src="logoUrl" width="300px" class="mb-3" />
      <b-row class="auth-inner m-0" style="max-width: 800px !important">
        <b-col cols="12" class="auth-bg p-2" style="border-radius: 15px">
          <div class="mb-2 mt-75">
            <h1 class="text-center">Cadastro de Professor (Integrado)</h1>
            <div class="text-center" v-if="google_user_email">
              <span class="text-muted"
                >Solicitante: <br />{{ google_user_email }} ({{
                  google_user_name
                }})</span
              >
            </div>
            <div v-else class="text-center mt-1">
              <h4>Você precisa logar para continuar</h4>
            </div>
          </div>

          <b-col cols="12" class="px-xl-2 mx-auto">
            <b-form>
              <b-row v-if="google_user_email">
                <b-col md="12">
                  <b-form-group label="Nome Professor *">
                    <b-form-input
                      v-model="record.name"
                      placeholder="Digite nome completo..."
                      autocomplete="off"
                      @keyup="generateEmailCompany"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="E-mail Pessoal *">
                    <div class="form-label-group">
                      <b-form-input
                        v-model="record.email_personal"
                        placeholder="Informe um e-mail"
                        autocomplete="off"
                      />
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="E-mail corporativo">
                    <div class="form-label-group">
                      <b-form-input
                        disabled
                        v-model="record.email"
                        autocomplete="off"
                      />
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="CPF *">
                    <div class="form-label-group">
                      <b-form-input
                        v-mask="$utils.masked.cpf"
                        v-model="record.cpf"
                        placeholder="Digite o cpf..."
                        autocomplete="off"
                      />
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Celular *">
                    <div class="form-label-group">
                      <b-form-input
                        v-mask="$utils.masked.phone"
                        v-model="record.phone"
                        placeholder="(xx) xxxxxxxx"
                        autocomplete="off"
                      />
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-button
                variant="info"
                @click="loginWithGoogle"
                block
                v-if="!google_token"
              >
                <img class="ml-50" v-if="!loading" :src="googleIconUrl" />
                <b-spinner type="grow" v-if="loading" />
                {{ loading ? "" : "ENTRAR COM O GOOGLE" }}
              </b-button>
              <b-button
                variant="info"
                block
                v-if="google_token"
                @click="createTeacher"
              >
                <b-spinner type="grow" v-if="loading" />
                {{ loading ? "" : "CADASTRAR PROFESSOR" }}
              </b-button>
            </b-form>
          </b-col>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
/* eslint-disable global-require */
import useAppConfig from "@core/app-config/useAppConfig";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import pt_br from "vee-validate/dist/locale/pt_BR.json";
import {
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import _accountsService from "@/services/accounts-service";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      logo: require("@/assets/images/logo/logo.svg"),
      googleIcon: require("@/assets/images/svg/google.svg"),

      google_user_email: "",
      google_token: "",
      google_user_name: "",
      google_user_image: "",
      loading: false,
      record: {
        email: "",
        email_personal: "",
        cpf: "",
        name: "",
        phone: "",
      },
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
    logoUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.logo = require("@/assets/images/logo/logo_dark.svg");
        return this.logo;
      }
      return this.logo;
    },
    googleIconUrl() {
      return this.googleIcon;
    },
  },
  methods: {
    loginWithGoogle() {
      this.$gAuth
        .signIn()
        .then((GoogleUser) => {
          const profile = GoogleUser.getBasicProfile();
          const email = profile.getEmail();
          if (email.split("@")[1] == "estrategia.com") {
            this.google_user_email = email;
            this.google_user_name = profile.getName();
            this.google_token = GoogleUser.getAuthResponse().id_token;
            this.google_user_image = profile.getImageUrl();
          } else {
            this.ShowAlert(
              "Você não pode fazer esse tipo de tarefa, apenas emails com domínio @estrategia.com"
            );
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    generateEmailCompany(event) {
      const value = this.$utils.removeAccents(event.target.value).toLowerCase();
      let username = value;

      if (value.split(" ").length >= 2) {
        username = `${value.split(" ")[0]}.${
          value.split(" ")[value.split(" ").length - 1]
        }`;
      }

      this.record.email = `edu.${username}@estrategia.com`;
    },
    createTeacher() {
      if (this.validIForm()) {
        this.loading = true;
        const payload = {
          email_requesting: this.google_user_email,
          ...this.record,
        };

        _accountsService
          .post(payload)
          .then((res) => {
            this.$utils.toast("SUCESSO", "Professor cadastrado com sucesso");
            setTimeout(() => {
              window.location.reload();
            }, 10000);
          })
          .catch((error) => {
            this.ShowAlert(error);
          })
          .finally(() => (this.loading = false));
      }
    },
    validIForm() {
      return true;
    },

    ShowAlert(msg) {
      this.$swal({
        title: "Erro ao Logar!",
        text: msg,
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    setConfigAccounts() {
      this.$store.dispatch("verticalMenu/UpdateMenuCollapsed", false);
      localStorage.setItem("login-accounts", "true");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>