import HttpService from './HttpService'

const _http = new HttpService()
export default {
  post: (payload) => {
    return _http.post(`/api/accounts`, payload)
  },
  getLogs: (email) => {
    return _http.get(`/api/accounts/${email}/logs`)
  }
}